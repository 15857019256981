import baseTextFieldForm from 'formiojs/components/textfield/TextField.form';
import { ignoreBuilderFields } from '#web-components/utils';
import {
  defaultConditionalFields,
  defaultDataFields,
  defaultValidationFields,
  tableSettingsTab,
  defaultApiFields,
} from '#web-components/components/Form/constants';

/**
 * This function returns the fields that should be present in the builder
 * You can extend the default one of any component
 * The values you pass are merged with the passed ones using the function unifyComponents (seacrh in formio.js source)
 *
 * *overrideEditForm* param is used when component key is equal to the default one to favor yours
 * *ignore* param will force the skipping of this component
 */
export default () => {
  return baseTextFieldForm([
    {
      key: 'display',
      components: [
        ...ignoreBuilderFields([
          'widget.type',
          'widget',
          'allowMultipleMasks',
          'customClass',
          'showWordCount',
          'showCharCount',
          'autofocus',
          'spellcheck',
          'modalEdit',
          'hideLabel',
          'tableView',
        ]),
        {
          key: 'autocomplete',
          defaultValue: 'on',
          overrideEditForm: true,
        },
      ],
    },
    {
      key: 'data',
      components: [
        ...ignoreBuilderFields([
          'multiple',
          'persistent',
          'inputFormat',
          'protected',
          'dbIndex',
          'encrypted',
          'redrawOn',
          'calculateServer',
        ]),
        ...defaultDataFields,
        {
          label: 'Phone Input',
          key: 'phoneInput',
          input: true,
          type: 'checkbox',
          defaultValue: false,
          tooltip: 'Remove all non-numeric symbols on submission.',
          weight: 10,
        },
      ],
    },
    {
      key: 'validation',
      components: [
        {
          label: 'Trim Spaces',
          key: 'trimSpaces',
          input: true,
          type: 'checkbox',
          defaultValue: false,
          tooltip: 'Remove leading and trailing spaces',
          weight: 10,
        },
        ...ignoreBuilderFields(['errorLabel', 'json-validation-json', 'unique']),
        ...defaultValidationFields,
      ],
    },
    {
      key: 'api',
      components: [
        ...ignoreBuilderFields(['tags', 'properties']),
        ...defaultApiFields,
      ],
    },
    {
      key: 'logic',
      ignore: true,
    },
    {
      key: 'layout',
      ignore: true,
    },
    {
      key: 'conditional',
      components: defaultConditionalFields,
    },
    tableSettingsTab,
  ]);
};
