import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Container from '#web-components/components/Container';
import Loader from '#web-components/components/Loader';
import Typography from '#web-components/components/Typography';
import RegistryName from '#web-components/components/Layouts/components/RegistryName';
import styles from './SidebarLayout.styles';

export interface SidebarLayoutProps {
  navbar: React.ReactNode
  children: React.ReactNode;
  title?: string;
  registryName?: string;
  registryFullName?: string;
  isLoading?: boolean;
  loaderDescription?: string;
  sideBarContent?: string | React.ReactElement;
  classes?: { sidebarContainer: string };
}

const useStyles = makeStyles(styles, { name: 'SidebarLayout' });

export default function SidebarLayout({
  children,
  title,
  registryName,
  registryFullName,
  navbar,
  isLoading = false,
  loaderDescription,
  sideBarContent = '',
  classes: classesProps,
}: SidebarLayoutProps) {
  const classes = useStyles();
  const isVisibleRegistryName = registryName && registryFullName;

  return (
    <Container noPadding>
      <Grid wrap="nowrap" container>
        <Grid item className={classes.mainContainer}>
          {isVisibleRegistryName && (
            <RegistryName name={registryName} fullName={registryFullName} />
          )}
          {navbar}
          <Container>
            <Loader show={isLoading} description={loaderDescription} data-xpath="component-loader" />
            {title && <Typography variant="h2" component="h1" className={classes.title}>{title}</Typography>}
            {children}
          </Container>
        </Grid>
        <Grid item className={clsx(classes.sidebarContainer, classesProps?.sidebarContainer)}>
          {
            typeof sideBarContent === 'string' && (
              <Typography variant="bodyText">
                {sideBarContent}
              </Typography>
            )
          }
          {
            typeof sideBarContent !== 'string' && (
              sideBarContent
            )
          }
        </Grid>
      </Grid>
    </Container>
  );
}
